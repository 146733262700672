import { Stack, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import React, { useMemo } from 'react';

import LoadingAnimation from '@fhs-legacy/frontend/src/components/loading-animation';
import LoadingContainer from '@fhs-legacy/frontend/src/components/loading-animation/loading-container';
import NotFound from '@fhs-legacy/frontend/src/pages/not-found';
import { RenderCurrentStaticPage } from '@fhs-legacy/frontend/src/pages/static/render-current-static-page';
import { StaticContentLanguageProvider } from '@fhs-legacy/frontend/src/pages/static/static-content-language';
import {
  GetStaticPageQuery,
  GetStaticPageRoutesQuery,
  IGetStaticPageQuery,
  IGetStaticPageRoutesQuery,
  IGetStaticPageVariables,
  IStaticPage,
} from '@fhs-legacy/frontend/src/remote/queries/static-page';
import { useSanityQuery } from '@fhs-legacy/frontend/src/state/graphql';
import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { Box } from '@fhs-legacy/universal-components';

const Container = Box.withConfig({
  flex: 1,
  paddingTop: 0,
  marginTop: 0,
});

const useStaticPage = (staticPageSlug: string) => {
  const { data: staticPageRoutes = { allStaticPage: [] }, loading: isStaticPageRoutesLoading } =
    useSanityQuery<IGetStaticPageRoutesQuery>(GetStaticPageRoutesQuery, {}, { isV2: true });
  const routes = staticPageRoutes.allStaticPage;
  const { language: appLanguage } = useLocale();

  const { route: targetPageRoute, defaultLanguage: staticPageDefaultLanguange } = useMemo(() => {
    for (const route of routes || []) {
      if (route.localePath?.en?.current === staticPageSlug) {
        return { route, defaultLanguage: 'en' } as const;
      }

      if (route.localePath?.fr?.current === staticPageSlug) {
        return { route, defaultLanguage: 'fr' } as const;
      }

      if (route.path.current === staticPageSlug) {
        return { route, defaultLanguage: appLanguage as 'en' | 'fr' } as const;
      }
    }

    return {};
  }, [routes, staticPageSlug, appLanguage]);

  const { data: staticPage, loading: isStaticPageLoading } = useSanityQuery<
    IGetStaticPageQuery,
    IGetStaticPageVariables
  >(GetStaticPageQuery, {
    variables: targetPageRoute
      ? {
          id: targetPageRoute?._id,
        }
      : undefined,
    skip: !targetPageRoute,
  });

  const isLoading = isStaticPageRoutesLoading || isStaticPageLoading;
  const isNotFound = !isStaticPageRoutesLoading && !targetPageRoute;

  return {
    isNotFound,
    isLoading,
    staticPage: staticPage?.StaticPage,
    staticPageDefaultLanguange,
    staticPageRoutes,
  };
};

const StaticPageContainer = () => {
  const { staticPage: staticPageSlug = '' } = useLocalSearchParams<{ staticPage: string }>();
  const { isNotFound, staticPageDefaultLanguange, staticPage, staticPageRoutes } =
    useStaticPage(staticPageSlug);
  if (isNotFound) {
    return <NotFound />;
  }
  if (!staticPage) {
    return (
      <>
        <Stack.Screen options={{ title: '' }} />
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
        <span>
          {JSON.stringify({ staticPageRoutesLength: staticPageRoutes.allStaticPage.length })}
        </span>
      </>
    );
  }
  return (
    <>
      <StaticPage targetPageDefaultLanguage={staticPageDefaultLanguange} currentPage={staticPage} />
      <span>
        {JSON.stringify({ staticPageRoutesLength: staticPageRoutes.allStaticPage.length })}
      </span>
    </>
  );
};

const StaticPage = ({
  targetPageDefaultLanguage,
  currentPage,
}: {
  targetPageDefaultLanguage?: 'en' | 'fr';
  currentPage: IStaticPage;
}) => {
  return (
    <StaticContentLanguageProvider defaultLanguage={targetPageDefaultLanguage}>
      <Box testID="static-page" flex={1}>
        <Head>
          <title>{`${currentPage.title} - Firehouse Subs`}</title>
        </Head>
        <Stack.Screen options={{ title: currentPage.title }} />
        <Container>
          <RenderCurrentStaticPage currentPage={currentPage} />
        </Container>
      </Box>
    </StaticContentLanguageProvider>
  );
};

export default StaticPageContainer;

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

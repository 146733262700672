import { useState } from 'react';
import { FlatList, View } from 'react-native';

import { Button, IconClose, Text } from '@fhs/ui';
import LocalStorage from '@fhs-legacy/frontend/src/utils/local-storage';

import { __resetCartState } from './backend/tmp-cart';
import { GetCart, useQuery } from './screens/client';

// DEBUGGER STUFF
type Log = { title: string; logs: any[] };

let logs: Log[] = LocalStorage.getItem('RBI:V2:LOGS') ?? [];

export type Logger = ReturnType<typeof createLogger>;

export function createLogger(title: string) {
  const state = {
    title,
    logs: [],
  };
  logs.push(state);
  return {
    log: function log(...args: any) {
      // eslint-disable-next-line
      console.log(`[${title}] - `, ...args);
      state.logs.push(args);
      LocalStorage.setItem('RBI:V2:LOGS', logs);
    },
  };
}

export function Debugger() {
  const [showDebugger, setShowDebugger] = useState(false);
  const { data: cart } = useQuery(GetCart);

  return !showDebugger ? (
    <Button
      onPress={() => setShowDebugger(true)}
      style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
    >
      <Button.Text>Debugger</Button.Text>
    </Button>
  ) : (
    <FlatList
      style={{ padding: 16, zIndex: 2 }}
      data={logs.toReversed()}
      ListHeaderComponent={() => {
        return (
          <>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text.Heading type="hero">Current</Text.Heading>
              <View style={{ width: 200, gap: 16, flexDirection: 'row' }}>
                <Button
                  style={{ flex: 2 }}
                  type="outline"
                  onPress={() => {
                    __resetCartState();
                    LocalStorage.setItem('RBI:V2:LOGS', []);
                    logs = [];
                  }}
                >
                  <Button.Text>Reset State</Button.Text>
                </Button>
                <Button onPress={() => setShowDebugger(false)}>
                  <Button.Icon>
                    <IconClose />
                  </Button.Icon>
                </Button>
              </View>
            </View>
            <table>
              <tbody>
                {Object.entries(cart ?? {}).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{JSON.stringify(value, null, 4)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h2>Changes</h2>
          </>
        );
      }}
      ItemSeparatorComponent={() => (
        <View style={{ backgroundColor: 'black', width: '100%', height: 4, marginBottom: 16 }} />
      )}
      renderItem={({ item }) => {
        return (
          <>
            <Text.Heading type="two">{item.title}</Text.Heading>
            <View style={{ gap: 8, marginTop: 16 }}>
              {item.logs.map((log: any[], i) => (
                <Text.Paragraph key={i} size="md">
                  • {log.join(' ')}
                </Text.Paragraph>
              ))}
            </View>
          </>
        );
      }}
    />
  );
}

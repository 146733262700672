import { Offer } from '../types';

export const data: Offer[] = [
  {
    id: '123',
    type: 'GLOBAL_DISCOUNT',
    name: '$4 OFF',
    description: 'Enjoy $4 off your order',
    expiration: '04/02',
    imageUrl:
      'https://s3-alpha-sig.figma.com/img/10f4/b1d7/b085d07601c555f48f6cc1d1c5ac88c6?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hgO6o-Q~xUd7-wfbRzIDTRC~31lkFZPA0nN~ltIyeoz5rtSVxvsSILuL0PGp2DfN1Ja5-m9SnYAh~qmrpuLFvdudbhdIbS2HI-0GbgU1dvXZpMsjk-02Now7F8W2gCz7maJPZMhuq~yUdqFxZhbVii2tkIAIxTEUQAr6jEgHSOWWB8are7ip4ZtzFNtzOBkw4tOQy0dAmgc-ZknjX-2ZznVg82z-unyxVg9lrfauxCiA-37A40aoEQS08~~RalACgEZicVT-kuRYaSrhsg53yhV4nAlAm~6X-GDk1kPQw88wHsbvItgMM~ZlH0aQJdmgHx9he9LuiCwtfWhJpzn7aQ__',
  },
  {
    id: '456',
    type: 'BOGO',
    name: 'BOGO',
    description: 'Buy one get one free!',
    expiration: '02/22',
    imageUrl:
      'https://s3-alpha-sig.figma.com/img/515f/712d/3799ce722d216ed15e918252332f092a?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Q-G-wIYHQNYnxH5vINGUOS~xqTDFQIYTp~RxUK3IyhXgX1D00ApYfEVXSk3KGdCIEBk0yuCHt6GfX20RDnlAG07YScTnp1JVmHlBkisydO0WYxx20Yh~2PKC3vEoaUb-EHEHEskonlvK2zGi~5ko0oaNhH7dsNnNm7m3DQ-Ryv3DDPFaN8uEB~hM2hJ--5VTfmBDLJC7nFvlYnBj71vYGrfqd5iq4icZISLYm-CbaC-MmoNygg~~JaOItgsVPA~AK4w~DjFZGa2je-QErsrueR7OHrHf0hcn5tksFtDAeJecN~TzbQ8hMu08WbqG3LOQSzirCopk0Xtlgol6R9sHFA__',
  },
  {
    id: '789',
    type: 'FIXED_ITEM_DISCOUNT',
    name: 'Buy 2 Medium Subs for $18',
    description: 'Any 2 medium subs for $18',
    expiration: '07/22',
    imageUrl:
      'https://s3-alpha-sig.figma.com/img/76fa/8ca6/eeca4c44a38dac9d454743bcf0357fc9?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fld4R2O4KsrAaFcRd9rkrXuO5XUquAHH3BYBE2WYF2jSVKVKXhTnyLu0TIgUEohHAAvG00HDQ-AQJQg6yuyA~YHXxV3FUBNLS8bxf-lQmE6Gsrzw6BCLJi9aSTDtGwBLnZAN9Uq3hKSK~2rDjm3gyKMeXuNofYC-z0UeH7cTdGtKRwyUITuWs7YpHAzImFLIr2AYT-mJoKltp1-nVxW6E4y3aJTY0nOTng~v~t8b4cvd4fhlBqek~DcwtEHrNrXY6PWDYRfNn7lCYF2ZZHCeCB7HdoYJTaw1BWmMUsXdYCC7WH7RfIXj2m0f36vEapAZ6IZyr4R3cjLyehwgzJB3bw__',
  },
];

import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { AddOfferToCart, useMutation } from '@fhs/cart';
import { Button, Text, tokens } from '@fhs/ui';

import { Offer } from '../../types';

type Props = {
  offer: Offer;
  selected: boolean;
};

export function OfferListTile(props: Props) {
  const [{ fetching: addingOffer }, addOfferToCart] = useMutation(AddOfferToCart);

  return (
    <View style={styles.offerItemContainer}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: props.offer.imageUrl }} contentFit="cover" style={styles.image} />
      </View>
      <View style={styles.textContainer}>
        <Text.Ui size="md" weight="bold">
          {props.offer.name}
        </Text.Ui>
        <Text.Ui size="sm" style={styles.expiration}>
          Expires {props.offer.expiration}
        </Text.Ui>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          disabled={props.selected}
          type="outline"
          size="xs"
          style={styles.button}
          onPress={() => {
            addOfferToCart({ offerId: props.offer.id });
          }}
          loading={addingOffer}
        >
          <Button.Text>{props.selected ? 'Offer Applied' : 'Apply Offer'}</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  offerItemContainer: {
    width: 165,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
    borderRadius: 8,
    height: 231,
    alignItems: 'center',
  },
  expiration: {
    marginTop: 8,
  },
  textContainer: {
    flex: 1,
    padding: 12,
    width: '100%',
  },
  button: {
    padding: 12,
  },
  image: {
    flex: 1,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  imageContainer: {
    height: 96,
    borderRadius: 0,
    backgroundColor: tokens.colors.$black10,
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$black10,
    width: '100%',
  },
  buttonContainer: {
    padding: 12,
    width: '100%',
  },
});

// This file mocks the idea of a dynamo stream lambda
// that is called when the cart db changes for a record
import { http as loyaltyHttp } from '@fhs/loyalty';

import { createLogger } from '../debugger';
import { CartModel } from '../types';

import { fetchOrderStatus } from './legacy/fetch-order-status';
import { updateCart } from './update-cart';

// The dynamo stream for the cart is effectively a state machine
// that will operate as a state machine
export async function dynamoStream(cart: CartModel) {
  const logger = createLogger('dynamostream');
  switch (cart.state) {
    case 'NEEDS_LOYALTY_VALIDATION':
      logger.log('Handling NEEDS_LOYALTY_VALIDATION cart state');
      const pointCostPromise = loyaltyHttp.receivePointCosts(cart);

      if (cart.appliedIncentives.length) {
        const discounts = await loyaltyHttp.receiveDiscounts(cart);
        cart.appliedIncentives[0] = {
          ...cart.appliedIncentives[0],
          ...discounts[0],
        };
        const updates = await loyaltyHttp.validateCart(cart);
        cart.state = updates.state;
      } else {
        cart.state = 'VALID';
      }
      const pointCost = await pointCostPromise;
      logger.log('Mapping point costs into cart entries');
      cart.entries = cart.entries.map(entry => {
        const pointResponse = pointCost.find(p => p.lineId === entry.lineId);
        if (pointResponse?.rewardIsRedeemable) {
          entry.pointCost = pointResponse.points;
        }
        return entry;
      });
      await updateCart(cart, logger);
      break;

    case 'PRICE_SUCCESSFUL':
      break;

    // When commit works, we need to poll for the response
    case 'INSERT_REQUESTED':
      await pollOrder(cart.metadata.rbiOrderId, logger, {
        INSERT_REQUESTED: async () => {
          await updateCart(cart, logger);
        },
        INSERT_SUCCESSFUL: async () => {
          cart.state = 'INSERT_SUCCESSFUL';
          await updateCart(cart, logger);
        },
      });
      break;

    // a fake implementation to let the UI progress. In the end
    // I think we'll want to attach a dynamostream to the monotable to ping
    // updates back to us on the price status
    case 'PRICE_REQUESTED':
      await pollOrder(cart.metadata.rbiOrderId, logger, {
        PRICE_ERROR: async () => {
          // TODO Attach information for the user
          cart.state = 'PRICE_ERROR';
          await updateCart(cart, logger);
        },
        PRICE_REQUESTED: async () => {
          await updateCart(cart, logger);
        },

        PRICE_SUCCESSFUL: async order => {
          cart.state = order.status;
          const cartPrice = cart.entries.reduce((p, i) => p + i.price, 0);

          logger.log('Price successful', order);

          if (cartPrice !== order.cart.subTotalCents) {
            logger.log(
              `cart price differs!! cartPrice: ${cartPrice}, posPrice: ${order.cart.subTotalCents}`
            );
            // Not sure if this should be the proper state for when the
            // prices do not match..
            // cart.state = 'ERROR';
            // TODO: Add some error to the cart for the user
            // cart.errors.push({
            //   message: 'Our prices have just been updated, please observe your cart and proceed again'
            // })

            // TODO:
            // triggerMenuSyncAndUpdateCartPrices();
          }
          await updateCart(cart, logger);
        },
      });
      break;

    default:
      logger.log(`No handler for ${cart.state}`);
  }
}

type Handlers = { [key: string]: (order: any) => any };
async function pollOrder(rbiOrderId, logger, handlers: Handlers): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const response = await fetchOrderStatus(rbiOrderId, logger);
        const handler = handlers[response.status];

        if (!handler) {
          logger.log('unhandled handler for order state', response.status);
          return;
        }
        await handlers[response.status]?.(response);
        resolve();
      } catch (err) {
        reject(err);
      }
    }, 2000);
  });
}

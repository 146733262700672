import { useEffect, useState } from 'react';

import { _getOffers } from '../backend/graphql';
import { Offer } from '../types';

export const GetOffers = `query {
  offers {
    id
    name
    imageUrl
  }
}`;

let memoryStore: null | Offer[] = null;
export function useQuery(query: typeof GetOffers) {
  const [state, setState] = useState({
    fetching: !memoryStore,
    error: null,
    data: memoryStore,
  });

  useEffect(() => {
    switch (query) {
      case GetOffers:
        _getOffers()
          .then(data => {
            memoryStore = data;
            setState({ fetching: false, error: null, data });
          })
          .catch(error => setState({ fetching: false, data: null, error }));
        break;
    }
  }, [query]);

  return state;
}
